import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loading } from '@/components';
import { eticketPortal } from '@/services';
import { Ticket } from '@/services/types';
import { updateThemeColor } from '@/utils/color';
import { getCurrentLocale } from '@/utils/locale';
import ud from '@/utils/ud';
import styles from './ticket.module.css';
import { TicketContext } from './context';

const TicketContainer: React.FC = () => {
  const { supplierId, secret, bookingId, ticketId, distributionId } =
    useParams();
  const [ticket, setTicket] = useState<Ticket>({});
  const [ticketRedeeming, setTicketRedeeming] = useState<boolean>(false);
  const loading = useMemo(() => !ticket.id, [ticket]);
  const { i18n } = useTranslation();
  const getTicket = useCallback(() => {
    return eticketPortal
      .GetTicket({
        bookingId,
        supplierId,
        secret,
        ticketId,
        distributionId,
        ud,
        locale: getCurrentLocale(i18n.language)
      })
      .then(res => {
        if (res?.ticket) {
          setTicket(res.ticket);
        }
      });
  }, [supplierId, secret, bookingId, ticketId, distributionId, i18n.language]);

  useEffect(() => {
    getTicket();
  }, [getTicket]);

  useEffect(() => {
    if (ticket.globalUiSetting?.colorPrimaryHex) {
      updateThemeColor(ticket.globalUiSetting?.colorPrimaryHex);
    }
  }, [ticket.globalUiSetting?.colorPrimaryHex]);

  const context = useMemo(
    () => ({
      ticket,
      setTicket,
      getTicket,
      ticketRedeeming,
      setTicketRedeeming
    }),
    [ticket, getTicket, ticketRedeeming, setTicketRedeeming]
  );

  return (
    <TicketContext.Provider value={context}>
      {loading ? (
        <div className={styles.loader}>
          <Loading />
        </div>
      ) : (
        <Outlet />
      )}
    </TicketContext.Provider>
  );
};

export default TicketContainer;
